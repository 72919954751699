.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 10px 0;
    padding: 10px;

    background-color: blueviolet;
}

.item_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.delete_icon {
    width: 40px;
    cursor: pointer;
}