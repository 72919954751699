.header {
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 50px;
    background-color: black;
    box-shadow: 0 0 10px rgb(27, 27, 27);
    z-index: 2;

    user-select: none;
}

.logo {
    width: 30px;
    margin-right: 10px;
}

.hamburger {
    display: none;
    width: 40px;
    cursor: pointer;
    margin-right: 30px;
}

.logo_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 70px;
    text-decoration: none;
}

.right_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.log_out {
    cursor: pointer;
    margin: 0 70px 0 20px;
}

.logo_text, .link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    
    cursor: pointer;
    text-decoration: none;
    color: aliceblue;
    margin-right: 70px;
}

.link:hover {
    color: red;
}

.menu_list {
    position: fixed;
    top: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    background-color: black;

    width: 100%;
}

.menu_list > .link {
    margin-right: 0;
    height: 50px;
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: unset;
    }
    
    .logo_bar {
        margin-left: 30px;
    }

    .logo_text, .right_bar {
        display: none;
    }
}