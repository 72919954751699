body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Comfortaa', cursive;
  background-color: #3F0071;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7303cd;

}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #FB2576;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c20e53;
}