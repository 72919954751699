.main {
    margin-top: 50px;

    height: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    color: white;
}
