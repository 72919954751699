.header_container {
    display: flex;
    flex-direction: column;
}

.poster, .none {
    width: 100%;
    height: 40vh;

    object-fit: cover;

    opacity: 1;
    z-index: 1;
    box-shadow: 0 2px 3px rgb(51, 51, 51);
    
    transition: height 1s ease-in-out; 
}

.none {
    height: 0;
    transition: height 1s ease-in-out; 
}

.navbar {
    list-style: none;
    display: flex;
    flex-direction: row;
    background-color: blueviolet;
    margin: 0;
    padding: 0;
    
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
}

.li_item{
    cursor: pointer;

    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.li_text {
    color: white;
    text-decoration: none;

    font-size: 22px;
    font-weight: 700;
    transition: .1s;
}

.li_text:hover {
    transform: scale(1.2);
}